import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import mtaClient from 'api/mtaClient';
import React, { useEffect, useReducer } from 'react';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { MtaStatus, UPDATE_MTA_QUOTE, useMtaQuote } from 'state/mta/mtaQuote';
import { usePolicyData } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';

type LoadMtaQuoteWrapperProps = {
  policyNumber: string;
};

const LoadMtaQuoteWrapper: React.FC<LoadMtaQuoteWrapperProps> = ({
  policyNumber,
  children,
}) => {
  const policyData = usePolicyData();
  const mtaQuoteData = useMtaQuote();
  const { isLoading, requestHandler } = useApiRequestHandler();
  const dispatch = useDispatch();

  const alreadyLoadedMtaQuote = mtaQuoteData?.policyNumber === policyNumber;
  const isNewMta =
    policyData?.policyNumber === policyNumber &&
    policyData?.mtaSummary?.mtaStatus !== MtaStatus.InProgress;

  const shouldLoadMta = !alreadyLoadedMtaQuote && !isNewMta;

  const [hasLoaded, setLoaded] = useReducer(() => true, !shouldLoadMta);

  useEffect(() => {
    if (!alreadyLoadedMtaQuote) {
      dispatch({ type: UPDATE_MTA_QUOTE, mtaQuote: null });
    }
    if (shouldLoadMta) {
      requestHandler(() =>
        mtaClient
          .getMtaQuote(policyNumber)
          .then((mtaQuote) => {
            dispatch({ type: UPDATE_MTA_QUOTE, mtaQuote });
            setLoaded();
          })
          .catch((error) => {
            if (isAxiosError(error)) {
              if (error.response.status === 404) {
                return;
              }
            }
            throw error;
          })
      );
    }
  }, [policyNumber, requestHandler, dispatch, alreadyLoadedMtaQuote, shouldLoadMta]);

  return isLoading || !hasLoaded ? (
    <LoadingOverlay loadingMessage="Loading policy change, please wait" />
  ) : (
    <>{children}</>
  );
};

export default LoadMtaQuoteWrapper;
