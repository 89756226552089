import { MtaQuote } from 'state/mta/mtaQuote';
import apiService from './apiService';
import {
  MtaAddOrEditDriverRequest,
  MtaChangeCarRequest,
  MtaEditAddressRequest,
  MtaRemoveDriverRequest,
} from './mta/mtaRequest';

export type MtaClient = {
  getMtaQuote: (policyNumber: string) => Promise<MtaQuote>;
  changeVehicle: (
    policyNumber: string,
    request: MtaChangeCarRequest
  ) => Promise<MtaQuote>;
  editAddress: (
    policyNumber: string,
    request: MtaEditAddressRequest
  ) => Promise<MtaQuote>;
  addDriver: (
    policyNumber: string,
    request: MtaAddOrEditDriverRequest
  ) => Promise<MtaQuote>;
  editDriver: (
    policyNumber: string,
    request: MtaAddOrEditDriverRequest
  ) => Promise<MtaQuote>;
  removeDriver: (
    policyNumber: string,
    request: MtaRemoveDriverRequest
  ) => Promise<MtaQuote>;
  confirm: (policyNumber: string) => Promise<MtaQuote>;
  deleteMta: (policyNumber: string) => Promise<MtaQuote>;
};

const mtaClient: MtaClient = {
  getMtaQuote: async (policyNumber: string) =>
    apiService.get(`policy/${policyNumber}/mtarequest`),
  changeVehicle: (policyNumber: string, request: MtaChangeCarRequest) =>
    apiService.put(`policy/${policyNumber}/mtarequest/amendvehicle`, request),
  editAddress: (policyNumber: string, request: MtaEditAddressRequest) =>
    apiService.put(`policy/${policyNumber}/mtarequest/amendaddress`, request),
  addDriver: (policyNumber: string, request: MtaAddOrEditDriverRequest) =>
    apiService.patch(`policy/${policyNumber}/mtarequest/adddriver`, request),
  editDriver: (policyNumber: string, request: MtaAddOrEditDriverRequest) =>
    apiService.patch(`policy/${policyNumber}/mtarequest/amenddriver`, request),
  removeDriver: (policyNumber: string, request: MtaRemoveDriverRequest) =>
    apiService.patch(`policy/${policyNumber}/mtarequest/removedriver`, request),
  confirm: (policyNumber: string) =>
    apiService.put(`policy/${policyNumber}/mtarequest/confirm`, {}),
  deleteMta: async (policyNumber: string) =>
    apiService.delete(`policy/${policyNumber}/mtarequest`),
};

export default mtaClient;
