import { graphql } from 'gatsby';
import React from 'react';
import ConfirmationPage from 'templates/ConfirmationPage';
import LoadMtaQuoteWrapper from 'components/ApiRequestWrapper/LoadMtaQuoteWrapper';
import Layout from 'components/Layout';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { useMtaQuote } from 'state/mta/mtaQuote';
import { useUser } from 'state/user/state';
import { CsAsset, CsMeta } from 'types/contentStack';

type ConfirmationProps = {
  data: {
    csMtaConfirmation: {
      meta: CsMeta;
      heading: string;
      subheading: string;
      cta_text: string;
      image: CsAsset | null;
    };
  };
};

export const query = graphql`
  query {
    csMtaConfirmation {
      meta {
        meta_title
      }
      cta_text
      heading
      subheading
      image {
        ...CsAsset
      }
    }
  }
`;

const Confirmation: React.FC<ConfirmationProps & PolicyNumberProps> = ({
  data,
  policyNumber,
}) => {
  const mtaQuote = useMtaQuote();

  const [user] = useUser();
  usePageTracking(
    data.csMtaConfirmation.meta.meta_title,
    !!user?.isLoggedIn && !!mtaQuote
  );

  const processedData = mtaQuote
    ? replaceMtaQuotePlaceholders(data.csMtaConfirmation, mtaQuote)
    : data.csMtaConfirmation;

  return (
    <Layout meta={processedData.meta} pageType="mtaAndRenewal">
      <LoadMtaQuoteWrapper policyNumber={policyNumber}>
        {mtaQuote && (
          <ConfirmationPage
            heading={processedData.heading}
            subheading={processedData.subheading}
            pageId="mta"
            ctaText={processedData.cta_text}
            image={processedData.image}
          />
        )}
      </LoadMtaQuoteWrapper>
    </Layout>
  );
};

export default withPolicyNumber(Confirmation);
